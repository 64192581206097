.carousel-wrapper {
  margin: auto;
  padding-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  z-index: -1;
  /* width: 70%; */
}

.carousel-wrapper img {
  /* height: 30vh; */
  width: 100vw;
}

@media screen and (max-width: 768px)  { 
  .carousel-wrapper {
    margin: 0;
    padding-bottom: 2rem;
    width: 100%;
  }
  

.carousel-wrapper img {
  height: 40vh;
}
}

/* @media screen and (min-width: 1690px)  { 
  .carousel-wrapper img {
    height: 85vh;
  }
} */