.benefitCard-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 30%;
}

.benefitCard-container p {
  text-align: center;
}

.benefitCard-container:hover {
  /* box-sizing:border-box; */
  box-shadow: 0rem 0rem 2rem 0rem var(--blue-ziix);
  /* background-color: var(--blue-white); */
  /* border: 1px solid var(--blue-ziix); */
}

.vantagens-wrapper h4 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 768px)  { 
  .benefitCard-container {
    padding: 2rem 1rem;
    width: 100%;
  }
}