.banner-container {
  align-items: center;
  display: flex;
  height: 90vh;
  justify-content: center;
  padding: 8rem;
  width: 100%;
  background: linear-gradient(180.08897980917595deg, rgba(222, 226, 227,1) -1.8628358004385972%,rgba(222, 226, 227,1) 18.40854577850877%,rgba(156, 211, 233,1) 50.16704358552632%,rgba(97, 199, 238,1) 79.22269051535088%,rgba(84, 196, 240,1) 86.40213815789473%);
}

.banner-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 15rem;
  justify-content: center;
  padding: 1rem;
}

.banner-wrapper h2 {
  color: var(--blue-dark);
  font-weight: 500;
  text-transform: uppercase;
}


@media screen and (max-width: 768px)  { 
  .banner-container {
    padding: 1.6rem;
  }

  .banner-wrapper {
    padding: 0;
  }
  
  .banner-wrapper h2 {
    text-align: center;
  }

  .banner-wrapper img {
    width: 100%;
  }
}