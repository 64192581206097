.empresa-container {
  padding: 7rem;
}

.empresa-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.empresa-wrapper h2 {
  margin-bottom: 3rem;
  font-size: 2.5rem;
  text-transform: uppercase;
}

.empresa-wrapper p {
  font-size: 1.25rem;
  text-align: center;
}

@media screen and (max-width: 768px)  { 
  .empresa-container {
    padding: 5rem 2rem;
  }
  
  .empresa-wrapper h2 {
    font-size: 2rem;
    text-align: center;
  }
  
  .empresa-wrapper p {
    font-size: 1.1rem;
    text-align: center;
  }

  .empresa-wrapper img {
    width: 100%;
  }
}