@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

:root {
  --blue-ziix: #25ABDE;
  --blue-light: #54c4f0;
  --blue-dark: #071d29;
  --blue-grey: #506C7D;
  --blue-white: #dee2e3;
  --blue-white-two: #bfcbce;
  --blue-dark-grey: #1f2629;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}    


