.contato-container {
  padding: 9rem 7rem;
  background-color: var(--blue-white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contato-container p {
  text-align: center;
}

#form-name {
  margin-left: 0.5rem;
}

.contato-container input {
  border: 0.1rem solid;
  border-color: var(--blue-ziix);
  color: var(--blue-dark);
  font-family: monospace;
  margin-bottom: 1.25rem;
  min-width: 240px;
  padding: 0.8rem;
}

form {
  display: flex;
  flex-direction: column;
}

.tooltip {
  display: inline-block;
  position: relative;
}

.tooltiptext {
  font-size: 0.5rem;
}

.tooltip .tooltiptext {
  background-color: var(--blue-grey);
  bottom: 25%;
  color: white;
  left: 130%;
  margin-left: -75px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  text-align: center;
  transition: opacity 0.3s;
  visibility: hidden;
  z-index: 1;
}

.tooltip .tooltiptext:after {
  border-color: var(--blue-grey) transparent transparent;
  border-style: solid;
  border-width: 5px;
  content: '';
  right: 85%;
  margin-left: -5px;
  position: absolute;
  top: 50%;
}

.tooltip:hover .tooltiptext {
  opacity: 1;
  visibility: visible;
}

.button {
  background-color: var(--blue-light);
  border: none;
  color: var(--blue-dark);
  display: flex;
  font-size: 1.25rem;
  font-weight: 600;
  justify-content: space-between;
  margin: 1rem 0;
  padding: 0.8rem;
  transition: 1s;
}

.email-btn {
  padding-right: 1rem;
}

.contato-descricao {
  font-size: 2rem;
  margin: 1rem 0;
}

.whatsapp {
  margin-left: 0.5rem;
  text-decoration: none;
  font-weight: bolder;
  color: var(--blue-ziix);
}

@media screen and (max-width: 768px)  { 
  .contato-container {
    padding: 9rem 2rem;
  }
  
  .contato-container p {
    font-size: 1.35rem;
  }

  .tooltip .tooltiptext {
    bottom: 120%;
    left: 113%;
  }

  .tooltip .tooltiptext:after {
    border-color: var(--blue-grey) transparent transparent;
    border-style: solid;
    border-width: 5px;
    content: '';
    right: 50%;
    margin-left: -5px;
    position: absolute;
    top: 97%;
  }
}
