.navbar {
  background-color: var(--blue-white);
  color: var(--blue-dark);
  display: flex;
  font-weight: 100;
  padding: 1.5rem;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.navbar-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar-container img {
  width: 4rem;
}

.nav-item, .nav-item * {
  color: var(--blue-dark);
  list-style: none;
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.7s ease;
}

.nav-item:hover * {
  color: var(--blue-ziix);
}

.nav-menu {
  display: flex;
  cursor: pointer;
}

.menu-icon {
  display: none;
}
 
@media screen and (max-width: 768px)  { 
  .menu-icon {
    display: block;
    color: var(--blue-dark);
    cursor: pointer;
    font-size: 1.8rem;
    transition: all 0.5s ease;
  }

  .menu-icon:hover {
    color: var(--blue-ziix);
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 101px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    /* background: var(--blue-white-two); */
    background: linear-gradient(180.08897980917595deg, rgba(222, 226, 227,1) -1.8628358004385972%,rgba(222, 226, 227,1) 18.40854577850877%,rgba(156, 211, 233,1) 50.16704358552632%,rgba(97, 199, 238,1) 79.22269051535088%,rgba(84, 196, 240,1) 86.40213815789473%);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item,.nav-item * {
    font-size: 1.5rem;
    margin-left: 0rem;
    margin-top: 4rem;
  }
}