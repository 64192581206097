.wolff-container {
  background-color: var(--blue-white);
  padding: 7rem;
  padding-bottom: 2rem;
}

.wolff-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.wolff-wrapper h2 {
  margin-bottom: 3rem;
  font-size: 2.25rem;
  text-transform: uppercase;
}

.wolff-description {
  font-size: 1.25rem;
  text-align: center;
}

.modelos-esquadrias {
  text-align: center;
  margin-top: 1rem;
}

@media screen and (max-width: 768px)  { 
  .wolff-container {
    padding: 5rem 2rem;
    padding-bottom: 2rem;
  }
  
  .wolff-wrapper h2 {
    font-size: 1.6rem;
    text-align: center;
  }
  
  .wolff-description {
    font-size: 1.1rem;

  }

  .wolff-wrapper img {
    width: 100%;
  }

  .modelos-esquadrias {
    font-size: 0.9rem;
  }
}