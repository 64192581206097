footer {
  align-items: center;
  background: var(--cream);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 1.5rem 2rem;
}

.comercial-info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.comercial-info p {
  font-size: 0.8rem;
  color: var(--gray-dark);
}

.social-media {
  display: flex;
  justify-content: space-around
}

.social-media a {
  color: var(--blue-dark);
  display: flex;
  font-size: 1.75rem;
  padding-left: 1.5rem;
  transition: all 0.5s ease;  
}

.social-media a:hover {
  color: var(--blue-ziix);
}

.social-media h3 {
  font-size: 1.75;
  margin-bottom: 0.75rem;
}

/* 
.social-icons {
  display: flex;
  justify-content: space-around
} */

.social-icons * {
  color: var(--magenta);
  font-size: 1.75rem;
  text-decoration: none;
}

@media screen and (max-width: 768px)  { 
  footer {
    padding: 1rem 1.5rem;
  }

  .comercial-info p {
    font-size: 0.6rem;
    width: 70%;
  }
}