.aluminio-container {
  background-color: var(--blue-white);
  padding: 7rem;
  padding-top: 3rem;
}

.aluminio-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.aluminio-wrapper h2 {
  margin-bottom: 3rem;
  font-size: 2.25rem;
  text-transform: uppercase;
}

.aluminio-description {
  font-size: 1.25rem;
  text-align: center;
}

.modelos-esquadrias {
  text-align: center;
  margin-top: 1rem;
}

@media screen and (max-width: 768px)  { 
  .aluminio-container {
    padding: 5rem 2rem;
    padding-top: 4rem;
  }
  
  .aluminio-wrapper h2 {
    font-size: 1.6rem;
    text-align: center;
  }
  
  .aluminio-description {
    font-size: 1.1rem;
  }

  .aluminio-wrapper img {
    width: 100%;
  }

  .modelos-esquadrias {
    font-size: 0.9rem;
  }
}